.App {
  margin-top: 15px;
}

.App .navbar-brand {
  color: #fff;
}

.navbar {
	background-color: #5893D7;
	color: #fff;
}
.navbar-default .navbar-nav>li>a {
    color: #fff;
	background-color: #5893D7;
}

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
    color: #fff;
    background-color: #095f94;
}
.navbar-default .navbar-brand:focus, .navbar-default .navbar-brand:hover {
    color: #fff;
    background-color: #095f94;
}
.Home .lander h1 {
	color: #5893D7
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #fff;
}
.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background-color: #095f94;
}